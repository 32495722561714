import router from './index';
import store from '@/store/index'
import Gate from '@/policy/Gate'
import config from '@/tstba.conf'

export default  (function(){


    router.beforeEach((to,from,next) => {
        if (to.matched.some(record =>record.meta.auth)) {
             if(!store.state.auth.connected) next({path:"/login"})
             else next()
        }else{
            next();
        }
    })
 
    router.beforeEach((to,from,next) => {
        if(store.state.auth.connected){
            if (to.query.intended === 'tbasp') {
                console.log(store.state.auth.token);
                store.dispatch('auth/setTecbillAuth', store.state.auth.token,true)
                location.replace(config.production_tip ? config.tba_sp_prod : config.tba_sp_dev)
            }
        }
        next()
     })

    router.beforeEach((to,from,next) => {
        if(store.state.auth.connected && to.name === 'Login Page') next({path:"/"})
        else next()
    })

    // router.beforeResolve((to,from,next) => {
        
    //     if (to.matched.some(record =>record.meta.auth)) {
    //         switch (to.name) {
    //             case 'Lock Page':
    //                 if(store.state.lock.locked)next()
    //                 else next('/')
    //                 break;
    //             default:
    //                 if(store.state.lock.locked) next({name:'Lock Page'})
    //                 next('/')
    //                 break;
    //         }
    //     }else{
    //         next()
    //     }
    // })

    router.beforeEach((to,from,next) => {
        if(store.state.auth.connected && to.fullPath === '/remboursements') {
            if(store.state.auth.company.setting.debt_actived === 0){
                next({name:"forbidden"})
            }
            next()
        }else{
            next()
        }
    })

    router.beforeEach((to,from,next) => {
        if(store.state.auth.connected && to.fullPath === '/credit-plafond-customers') {
            if(Gate.checkTypeRole(['admin','super-admin']) && store.state.auth.company.setting.debt_actived === 1){
                next()
            }else{
                next({name:"forbidden"})
            }
        }else{
            next()
        }
    })

    router.beforeEach((to,from,next) => {
        if(store.state.auth.connected && to.fullPath === '/ventes-credit') {
            if(store.state.auth.company.setting.debt_actived === 0){
                next({name:"forbidden"})
            }
            next()
        }else{
            next()
        }
    })

    router.beforeEach((to,from,next) => {
        if(to.name === 'Creer Facture' && 
        store.state.auth.company.setting.num_facture_status == 0 && 
        store.state.auth.company.setting.is_automatic === 1){
            setTimeout(()=>{
                if(Gate.checkTypeRole(['admin','super-admin'])){
                    store.dispatch('swal/mils',{
                        message:"La numérotation automatique est activé pour l'edition des factures. veuillez définir ses paramètres pour son bon fonctionnement dans  paramètres > facturation.",
                        type:"warning",
                        icon:"warning"
                    })
                }else{
                    store.dispatch('swal/mils',{
                        message:"Les paramètres de la numérotatin automatique sont manquants. Veuillez contacter votre administrateur.",
                        type:"warning",
                        icon:"warning"
                    })
                }
            },2000) 
        }

        if(to.name === 'Creer Facture' && store.state.auth.user.mode !== 'advanced'){
            next({name:'Creer Facture Simple'})
        }

        if(to.name === 'Clients' && store.state.auth.user.role === 'user'){
            next({name_diff:'Clients Simple'})
        }
       next()
    })


    router.beforeEach((to,from,next)=>{
        if (to.matched.some(record =>record.meta.role) && store.state.auth.connected ) {
            let pass = false;
            to.meta.role.forEach(element => {
                if (Gate.checkTypeRole(element)) {
                    pass  = true
                }
            })
             if(!pass) next({name:"forbidden"})
             else next()
        }else{
            next();
        }
    });

    router.beforeEach((to,from,next)=>{
        if (to.matched.some(record =>record.meta.roleperm) && store.state.auth.connected ) {
             if(!Gate.hasPermission(to.meta.roleperm)) next({name:"forbidden"})
             else next()
        }else{
            next();
        }
    });

    

})()