import axios from "../../services/fetch"

export default {
    namespaced: true,
    state:{
        errors:{
            from:"",
            to:"",
            ts:"",
            ht:"",
            ttc:"",
            sts:"",
            rcs:"",
            article_id:"",
            month:""
        }
    },
    getters: {},
    mutations:{
        SET_STAX(state, data) {
            if (data !== undefined) {
                state.errors.ts = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.ts = data
            }
        },
        SET_PHT(state, data) {
            if (data !== undefined) {
                state.errors.ht = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.ht = data
            }
        },
        SET_PTTC(state, data) {
            if (data !== undefined) {
                state.errors.ttc = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.ttc = data
            }
        },
        SET_TAX1(state, data) {
            if (data !== undefined) {
                state.errors.tax1 = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.tax1 = data
            }
        },
        SET_TAX2(state, data) {
            if (data !== undefined) {
                state.errors.tax2 = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.tax2 = data
            }
        },
        SET_ARTICLE(state, data) {
            if (data !== undefined) {
                state.errors.article_id = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.article_id = data
            }
        },
        SET_MONTH(state, data) {
            if (data !== undefined) {
                state.errors.month = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.month = data
            }
        },

        SET_FROM(state, data) {
            if (data !== undefined) {
                state.errors.from = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.from = data
            }
        },

        SET_TO(state, data) {
            if (data !== undefined) {
                state.errors.to = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.to = data
            }
        },
        SET_CLEAN(state){
            state.errors.ts = ""
            state.errors.to = ""
            state.errors.from = ""
            state.errors.ht = ""
            state.errors.ttc = ""
            state.errors.tax1 = ""
            state.errors.tax2 = ""
            state.errors.article_id = ""
            state.errors.month = ""
        }
    },
    actions:{
        /**
         * 
         * @param  param0 
         * @return response
         */
        async findAll({dispatch}){
        dispatch
            let response = await axios.get('/prices');
            return response
        },

        /**
         * 
         * @param {*} param0 
         * @param Integer id 
         */
        async findById({dispatch},id){
        dispatch
            let response = await axios.get('/prices/'+id)
            return response
        },

        /**
         * 
         * @param {*} param0 
         * @param Integer id 
         */
        async findByCodePrice({dispatch},data){
        dispatch
            let response = await axios.post('/prices/code',data)
            return response
        },

        async getTVA({dispatch}){
        dispatch
            let response = await axios.get('/getTVA')
            return response
        },

        async getConfigTs({dispatch}){
        dispatch
            let response = await axios.get('/config-ts')
            return response
        },

        /**
         *  Store 
         * @param {*} param0 
         * @param {*} data 
         */
        async save({dispatch},data){
        dispatch
            let response = await axios.post('/prices',data);
            return response
        },

        async duplicate({dispatch},data){
        dispatch
            let response = await axios.post('/prices/duplicate?m=all',data);
            return response
        },

        /**
         * 
         * @param {*} param0 
         * @param {*} data 
         */
        async update({dispatch},data){
        dispatch
            let response = await axios.put(`/prices/${data.id}`,data);
            return response
        },

        /**
         * 
         * @param {*} param0 
         * @param {*} id 
         */
        async delete({dispatch},id){
        dispatch
            let response = await axios.delete(`/prices/${id}`);
            return response
        }

    }
  }
  