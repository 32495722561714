import axios from "../../services/fetch"

export default {
    namespaced: true,
    state:{
        errors:{
            code_model:"",
            image:"",
            theme_color:"",
            name:"",
        }
    },
    getters: {},
    mutations:{
        SET_CODE(state, data) {
            if (data !== undefined) {
                state.errors.code_model = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.code_model = data
            }
        },
        SET_NAME(state, data) {
            
            if (data !== undefined) {
                state.errors.name = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.name = data
            }
        },

        SET_IMAGE(state, data) {
            
            if (data !== undefined) {
                state.errors.image = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.image = data
            }
        },
        SET_COLOR(state, data) {
            
            if (data !== undefined) {
                state.errors.theme_color = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.theme_color = data
            }
        },

       
        SET_CLEAN(state){
            state.errors.name = "";
            state.errors.theme_color = "";
            state.errors.code_model = "";
            state.errors.image = "";
        }
    },
    actions:{
        /**
         * 
         * @param  param0 
         * @return response
         */
        async findAll({dispatch},data){
        dispatch
            let response = await axios.get('/model_invoices?mode='+data.mode);
            return response
        },

        /**
         * 
         * @param {*} param0 
         * @param Integer id 
         */
        async findById({dispatch},id){
        dispatch
            let response = await axios.get('/model_invoices/'+id)
            return response
        },

        /**
         *  Store 
         * @param {*} param0 
         * @param {*} data 
         */
        async save( {dispatch}, data){
        dispatch
            let response = await axios.post('/model_invoices',data);
            return response
        },

        async activeModel( {dispatch}, data){
        dispatch
            let response = await axios.post('/model_invoices/active_model',data);
            return response
        },

        /**
         * 
         * @param {*} param0 
         * @param {*} data 
         */
        async update( {dispatch}, {data,id}){
        dispatch
            let response = await axios.post('/model_invoices/'+id+'?_method=PUT', data);
            return response
        },

        /**
         * 
         * @param {*} param0 
         * @param {*} id 
         */
        async delete({dispatch}, id){
        dispatch
            let response = await axios.delete(`/model_invoices/${id}`);
            return response
        }

    }
  }
  