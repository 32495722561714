import Vue from 'vue'
import VueNotify from 'vue-notifyjs'
import VueTheMask from 'vue-the-mask'
import NProgress from 'vue-nprogress'
import VueMeta from 'vue-meta'
import VueMobileDetection from 'vue-mobile-detection'
import moment from "moment";
moment.locale('fr');
import VueMomentJS from "vue-momentjs";
import themeSwitcher from "../src/utils/darklight";
import Lock from '@/services/system-lock/Lock'
import LoadScript from 'vue-plugin-load-script';


/**
 * Import Project
 */
import App from './App'
import router from './router'
import store from './store/index'
import Gate from '@/policy/Gate'
import globalConfig from './tstba.conf.js'

/***
 * Fichier static css et scss
 */
import 'bootstrap-css-only/css/bootstrap.min.css'
import 'mdbvue/lib/css/mdb.min.css'
import "mdb-calendar/lib/main.css"
import "@/assets/scss/font.scss"
import "@/assets/scss/login.scss"
import "@/assets/scss/style.scss"
import "@/assets/scss/table-tec.scss"
import "@/assets/scss/page.scss"
import "@/assets/scss/_dark.scss"
import "@/assets/scss/_light.scss"

if(!localStorage.getItem("theme")){ 
  themeSwitcher("theme-light") 
}else{
  themeSwitcher(localStorage.getItem("theme"))
}

/***
 * Element UI 
 */
import 'element-ui/lib/theme-chalk/index.css';
require('@/element-ui')
require('@/router/middleware')

/**
 * Requirement for http
 */
require('./store/subscriber.js'); 
require('./services/interceptor.js');

Vue.prototype.$gate = Gate
// Vue.prototype.$lock = Lock
Vue.use(VueTheMask)
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})

Vue.config.productionTip = globalConfig.production_tip
Vue.config.devtools =  (globalConfig.production_tip) ? false : true
Vue.config.silent = true

Vue.use(VueMobileDetection)
Vue.use(VueMomentJS, moment);
Vue.use(VueNotify)
Vue.use(LoadScript);

/**
 * Nprogress Configuration
 */
const options = {
  latencyThreshold: 600, // Number of ms before progressbar starts showing, default: 100,
  router: true, // Show progressbar when navigating routes, default: true
  http: false // Show progressbar when doing Vue.http, default: true
};
Vue.use(NProgress, options)
const nprogress = new NProgress()

import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

Vue.use(CoolLightBox)

Vue.filter('formatNumber', function (value) {
  if (!value) return 0
  return new Intl.NumberFormat().format(value)
})

Vue.filter('roundNumber', (value)=>{
  if(!value) return 0
  return  Math.round(value * 100) / 100
})

Vue.filter('smartNumber', (value)=>{
   
  if(value > 999 && value < 1000000){
      return (value/1000).toFixed(1) + 'K'; // convert to K for number from > 1000 < 1 million 
  }else if(value > 1000000){
      return (value/1000000).toFixed(1) + 'M'; // convert to M for number from > 1 million 
  }else if(value < 900){
      return value; // if value < 1000, nothing to do
  }
})
 
store.dispatch("auth/attempt", localStorage.getItem('token')).then((res) => {
  if (localStorage.getItem('syslock') !== 'stop') {
    Lock.check(res).then(()=>{
      Lock.timer()
    })
  }
  new Vue({
  render: h => h(App),
  nprogress,
  router,
  store,
  components: { App }
  }).$mount('#app')
});
  
