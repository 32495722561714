import axios from "../../services/fetch"

export default {
    namespaced: true,
    state:{ },
    getters: { },
    mutations:{ },
    actions:{
        /**
         * @param  param0 
         * @return response
         */
        async findAll({dispatch},data){
        dispatch
            let response = await axios.get('/my_models?mode='+data.mode);
            return response
        },

    }
  }
  