import axios from 'axios'
import globalConfig from "../tstba.conf.js"

const fetch = axios.create({
    baseURL:(globalConfig.production_tip) ? globalConfig.tba_server_prod+"api/" : globalConfig.tba_server_dev+"api/",
 
    withCredentials: false,
    headers: {
      'Access-Control-Allow-Origin' : '*',
      'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    }
});

export default fetch