import axios from "../../services/fetch"

export default {
    namespaced: true,
    state:{
        errors:{
            start:"",
            end:"",
            users:"",
            sites:"",
            articles:"",
        }
    },
    getters: {},
    mutations:{
        SET_START(state, data) {
            if (data !== undefined) {
                state.errors.start = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.start = data
            }
        },
        SET_END(state, data) {
            if (data !== undefined) {
                state.errors.end = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.end = data
            }
        },
        SET_USERS(state, data) {
            if (data !== undefined) {
                state.errors.users = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.users = data
            }
        },
        SET_SITES(state, data) {
            if (data !== undefined) {
                state.errors.sites = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.sites = data
            }
        },
        SET_ARTICLES(state, data) {
            if (data !== undefined) {
                state.errors.articles = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.articles = data
            }
        },
        SET_CLEAN(state){
            state.errors.start = ""
            state.errors.end = ""
            state.errors.users = ""
            state.errors.sites = ""
            state.errors.articles = ""
        }
    },
    actions:{
        /**
         * 
         * @param  param0 
         * @return response
         */
         async invoiceReports(...data){
            let response = await axios.post('/reports/invoices',data[1]);
            return response
        },
        
        /**
         * 
         * @param  param0 
         * @return response
         */
        async sitesReports({dispatch}){
        dispatch
            let response = await axios.get('/reports/sites');
            return response
        },

        /**
         * 
         * @param  param0 
         * @return response
         */
         async usersReports({dispatch}){
        dispatch
            let response = await axios.get('/reports/users');
            return response
        },

         /**
         * 
         * @param  param0 
         * @return response
         */
            async storageRecapReports({dispatch}){
            dispatch
                let response = await axios.get('/reports/storage_recap');
                return response
            },
    
             /**
             * 
             * @param  param0 
             * @return response
             */
            async recapRevenueMonth({dispatch},data){
                dispatch
                let response = await axios.get('/reports/revenues-recap?month='+data.month);
                return response
            },
    
             /**
             * 
             * @param  param0 
             * @return response
             */
              async recapRevenueWeek({dispatch}){
                dispatch
                let response = await axios.get('/reports/revenues-recap?week=1');
                return response
            },


         /**
         * 
         * @param  param0 
         * @return response
         */
          async revenueOnYear({dispatch}){
        dispatch
            let response = await axios.get('/reports/revenues?year=1');
            return response
        },

         /**
         * 
         * @param  param0 
         * @return response
         */
        async revenueOnMonth({dispatch},data){
            dispatch
            let response = await axios.get('/reports/revenues?month='+data.month);
            return response
        },

         /**
         * 
         * @param  param0 
         * @return response
         */
          async revenueOnWeek({dispatch}){
            dispatch
            let response = await axios.get('/reports/revenues?week=1');
            return response
        },

        /**
         * 
         * @param  param0 
         * @return response
         */
         async mtvaReports({dispatch}){
        dispatch
            let response = await axios.get('/reports/mtva');
            return response
        },

        /**
         * @param  param0 
         * @return response
         */
         async activitiesReports({dispatch}, data){
        dispatch
            let response = await axios.post('/reports/activities',data);
            return response
        },

        /**
         * @param  param0 
         * @return response
         */
         async quantitiesByArticleReports({dispatch}, data){
            dispatch
            let response = await axios.post('/reports/quantities_sold_by_article',data);
            return response
        },

        /**
         * @param  param0 
         * @return response
         */
         async quantitiesByArticleBySiteReports({dispatch}, data){
            dispatch
            let response = await axios.post('/reports/quantities_sold_by_site_article',data);
            return response
        },

        /**
         * @param  param0 
         * @return response
         */
         async caBySiteReports({dispatch}, data){
            dispatch
            let response = await axios.post('/reports/ca_by_site',data);
            return response
        },

        /**
         * @param  param0 
         * @return response
         */
         async caByUserReports({dispatch}, data){
            dispatch
            let response = await axios.post('/reports/ca_by_user',data);
            return response
        },

        /**
         * @param  param0 
         * @return response
         */
         async pdfActivitiesReports({dispatch}, data){
            dispatch
           let response = await axios.post('/reports/activities/pdf',data);
           return response
        },

    }
  }
  