import axios from "../../services/fetch"
import Lock from '../../services/system-lock/Lock'

import Cookies from "js-cookie";
import tstbaConf from "../../tstba.conf";

export default {
    namespaced: true,
    state:{
      connected: false,
      user: null,
      site: null,
      company: null,
      model: null,
      token: null,
      errors:{
        first_name:"",
        last_name:"",
        email:"",
        password:"",
        password_c:""
      }
    },
    getters: {
        connected: state => state.connected
      },
    mutations:{
      SET_ERRORS(state, data) {
        if (data) { 
          let k = Object.keys(data);

          for (let i = 0; i <  k.length; i++) {
            if ('first_name' === k[i]) {
              state.errors.first_name = data.first_name[0]
            }
            if ('last_name' === k[i]) {
              state.errors.last_name = data.last_name[0]
            }
            if ('email' === k[i]) {
              state.errors.email = data.email[0]
            }
            if ('password' === k[i]) {
              state.errors.password = data.password[0]
            }
            if ('password_c' === k[i]) {
              state.errors.password_c = data.password_c[0]
            }
            
          }
        }
        
      },

      UNSET_ERRORS(state) {
          state.errors.first_name = ""
          state.errors.last_name =  ""
          state.errors.email =  ""
          state.errors.password =  ""
          state.errors.password_c =  ""
        
      },

      SET_CONNECTED(state, data) {
        state.connected = data
      },

      SET_TOKEN(state, data) {
        state.token = data
      },

      SET_USER(state, data) {
        state.user = data
      },

      SET_SITE(state, data) {
        state.site = data
      },

      SET_COMPANY(state, data) {
        state.company = data
      },

      SET_MODEL(state, data) {
        state.model = data
      },
    },
    actions:{
      async login({dispatch}, credentials){
        let response = await axios.post('/auth/login', credentials);
        dispatch('setTecbillAuth', response.data)
        return dispatch('attempt', response.data)
      },

      setTecbillAuth({dispatch},token){
        dispatch
          const expirationHours = 10;
          const fractionOfDay = expirationHours / 24;

          // Calculer la date d'expiration
          const now = new Date();
          const expirationDate = new Date(now.getTime() + fractionOfDay * 24 * 60 * 60 * 1000);
          Cookies.set('_tecbill_auth', token, {  path: '/', domain: (tstbaConf.production_tip) ? '.tecbill.net': 'localhost',expires:expirationDate, secure:tstbaConf.production_tip })
      },

      logout({commit}){
        return axios.get('/auth/logout').then(() => {
          Cookies.remove('_tecbill_auth')
          commit('SET_TOKEN', null)
          commit('SET_USER', null)
          commit('SET_SITE', null)
          commit('SET_CONNECTED', false)
          Lock.unlock()
        })
      },

      async register({dispatch},data){
      dispatch
        let response = await axios.post('/auth/register', data);
        return response;
      },

      async attempt({ commit, state}, data){
        
        if(data){
          commit('SET_CONNECTED', true)
          commit('SET_TOKEN', data)
        }
        
        if (!state.token) {
          return
        }

        try {
          let response = await axios.post('/auth/me')

          commit('SET_USER', response.data.user);
          commit('SET_SITE', response.data.site);
          commit('SET_COMPANY', response.data.company);
          commit('SET_MODEL', response.data.model);
          return response.data.user;
        } catch (error) {
          
          commit('SET_TOKEN', null)

          commit('SET_USER', null)

          commit('SET_SITE', null)

          commit('SET_COMPANY', null)

          commit('SET_MODEL', null)

          commit('SET_CONNECTED', false)
          Lock.unlock()
        }
      },

      async forgotPassword({dispatch}, credentials){
    dispatch
        let response = await axios.post('/auth/forgot-password', credentials);
        return response;
      },

      async validateToken({dispatch}, credentials){
    dispatch
        let response = await axios.post('/auth/validate-token', credentials);
        return response;
      },

    }
}