import axios from "../../services/fetch"

export default {
    namespaced: true,
    state:{
        errors:{
            phone:"",
            email:"",
            firstname:"",
            lastname:"",
            code_commercial:"",
        }
    },
    getters: {},
    mutations:{
        SET_PHONE(state, data) {
            
            if (data !== undefined) {
                state.errors.phone = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.phone = data
            }
        },
        SET_EMAIL(state, data) {
            
            if (data !== undefined) {
                state.errors.email = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.email = data
            }
        },
        SET_FNAME(state, data) {
            
            if (data !== undefined) {
                state.errors.firstname = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.firstname = data
            }
        },
        SET_LNAME(state, data) {
            
            if (data !== undefined) {
                state.errors.lastname = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.lastname = data
            }
        },
        SET_CODE(state, data) {
            
            if (data !== undefined) {
                state.errors.code_commercial = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.code_commercial = data
            }
        },
        SET_CLEAN(state){
            state.errors.phone = "";
            state.errors.email = "";
            state.errors.firstname = "";
            state.errors.lastname = "";
            state.errors.code_commercial = "";
        }
    },
    actions:{
        /**
         * @param  param0 
         * @return response
         */
        async findAll({dispatch}, data){
        dispatch
            let response = await axios.get('/commercials?page='+data.page+'&limit='+data.perPage);
            return response
        },

        /**
         * @param {*} param0 
         * @param Integer id 
         */
        async findById({dispatch},id){
        dispatch
            let response = await axios.get('/commercials/'+id)
            return response
        },

        /**
         *  Store 
         * @param {*} param0 
         * @param {*} data 
         */
        async save({dispatch},data){
        dispatch
            let response = await axios.post('/commercials',data);
            return response
        },

        /**
         * 
         * @param {*} param0 
         * @param {*} data 
         */
        async update({dispatch},data){
        dispatch
            let response = await axios.put(`/commercials/${data.id}`,data);
            return response
        },

        /**
         * 
         * @param {*} param0 
         * @param {*} id 
         */
        async delete({dispatch},id){
        dispatch
            let response = await axios.delete(`/commercials/${id}`);
            return response
        }

    }
  }
  