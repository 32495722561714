import swal from 'sweetalert2'
export default {
    namespaced: true,
    state:{},
    getters: {},
    mutations:{},
    actions:{
        async ultimate ({dispatch}, {
            message,
            type,
            title,
            icon
        }) {
        dispatch
            let btn = "";

            switch (type) {
                case "danger":
                    btn = "danger"
                    break;
                case "info":
                    btn = "info"
                    break;
                
                case "warning":
                    btn = "warning"
                    break;
                case "success":
                    btn = "success"
                    break;
                default:
                    btn = "primary"
                    break;
            }

            let response = await swal.fire({
                title: title,
                text: message,
                icon: icon,
                showCancelButton: true,
                confirmButtonClass: 'btn  btn-'+btn+' btn-fill',
                cancelButtonClass: 'btn btn-outline-'+btn+' btn-fill  ',
                cancelButtonText: 'Non',
                confirmButtonText: 'Oui',
                buttonsStyling: false
            })

            return response
        },

        async doYouDelete () {
            let response = await swal.fire({
                title: 'Etes-vous sûr ?',
                text: `Vous ne pourrez pas revenir en arrière !`,
                icon: 'error',
                showCancelButton: true,
                confirmButtonClass: 'btn  btn-primary btn-fill',
                cancelButtonClass: 'btn btn-outline-primary btn-fill  ',
                cancelButtonText: 'Non',
                confirmButtonText: 'Oui',
                buttonsStyling: false
            })

            return response
        },

        async mils ({dispatch}, {
            message,
            type,
            title,
            icon
        }) {
        dispatch
            let btn = "";

            switch (type) {
                case "danger":
                    btn = "danger"
                    break;
                case "info":
                    btn = "info"
                    break;
                
                case "warning":
                    btn = "warning"
                    break;
                case "success":
                    btn = "success"
                    break;
                default:
                    btn = "primary"
                    break;
            }

            let response = await swal.fire({
                title: title,
                text: message,
                icon: icon,
                showCancelButton: false,
                confirmButtonClass: 'btn  btn-'+btn+' btn-fill',
                cancelButtonClass: 'btn btn-outline-'+btn+' btn-fill  ',
                cancelButtonText: 'Non',
                confirmButtonText: 'D\'accord',
                buttonsStyling: false
            })

            return response
        },

        async doYouPrintPdf(){
            let response = await swal.fire({
                title: "Imprimante d'impression",
                reverseButtons: false,
                showDenyButton: true,
                showCancelButton: true,
                confirmButtonText: `Normale`,
                cancelButtonText: `Annuler`,
                denyButtonText: `Thermique`,
            })

            return response
        }
    }
  }
  