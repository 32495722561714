import axios from "../../services/fetch"

export default {
    namespaced: true,
    state:{},
    getters: {},
    mutations:{},
    actions:{
        /**
         * @param  param0 
         * @return response
         */
        async findAll({dispatch}, data){
        dispatch
            let response = await axios.get('/enrolments?page='+data.page+'&limit='+data.perPage);
            return response
        },
    }
  }
  