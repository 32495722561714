import axios from "../../services/fetch"

export default {
    namespaced: true,
    state:{
        errors:{
        }
    },
    getters: {},
    mutations:{
        
    },
    actions:{
        /**
         * 
         * @param  param0 
         * @return response
         */
        async findAll({dispatch}){
        dispatch
            let response = await axios.get('/subscription_packs');
            return response
        },

         /**
         * 
         * @param  param0 
         * @return response
         */
          async findPrice({dispatch}){
            dispatch
                let response = await axios.get('/subscription_packs?price=1');
                return response
            },

        /**
         * 
         * @param {*} param0 
         * @param Integer id 
         */
        async findById({dispatch},id){
        dispatch
            let response = await axios.get('/subscription_packs/'+id)
            return response
        },

        /**
         *  Store 
         * @param {*} param0 
         * @param {*} data 
         */
        async save({dispatch},data){
        dispatch
            let response = await axios.post('/subscription_packs',data);
            return response
        },

        /**
         *  Updte 
         * @param {*} param0 
         * @param {*} data 
         */
         async update({dispatch},data){
             console.log(data);
            dispatch
                let response = await axios.put('/subscription_packs/'+data.id,data);
                return response
            },

        /**
         * 
         * @param {*} param0 
         * @param {*} id 
         */
        async delete({dispatch},id){
        dispatch
            let response = await axios.delete(`/subscription_packs/${id}`);
            return response
        }

    }
  }
  