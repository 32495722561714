import RoleIndexVue from '../tba/views/roles/RoleIndex.vue'

const  Home = () => import ('@/tba/views/homes/Home.vue')
const  FrontLayout = () => import ('@/tba/views/layouts/FrontLayout')
const  LoginPage = () => import ('@/tba/views/auth/Login')
const  LockIndex = () => import ('@/tba/views/auth/LockIndex')
const  ForgotPasswordPage = () => import ('@/tba/views/auth/ForgotPassword')
const  ChangePasswordPage = () => import ('@/tba/views/auth/ChangePassword')
const  NotFound = () => import ('@/tba/views/errors/404.vue')
const  Forbiden = () => import ('@/tba/views/errors/403.vue')

const  CompanyLayout = () => import ('@/tba/views/companies/CompanyLayout')
const  CompanyIndex = () => import ('@/tba/views/companies/CompanyIndex')

const  SiteIndex = () => import ('@/tba/views/sites/SiteIndex')

const  BackLayout = () => import ('@/tba/views/layouts/BackLayout')

const  DepotIndex = () => import ('@/tba/views/depots/DepotCategoryIndex.vue')
const  CategoryIndex = () => import ('@/tba/views/categories/CategoryIndex.vue')
const  PriceIndex = () => import ('@/tba/views/prices/PriceIndex.vue')
const  CustomerIndex = () => import ('@/tba/views/customers/CustomerIndex.vue')
const  CustomerUserIndex = () => import ('@/tba/views/customers/CustomerByUser.vue')
const  ArticleIndex = () => import ('@/tba/views/articles/ArticleIndex.vue')
const  UserIndex = () => import ('@/tba/views/users/UserIndex.vue')

const  InvoiceLayout = () => import ('@/tba/views/invoices/InvoiceLayout.vue')
const  InvoiceIndex = () => import ('@/tba/views/invoices/InvoiceIndex.vue')
const  InvoiceSearch = () => import ('@/tba/views/invoices/InvoiceSearch.vue')
const  InvoiceCreateIndex = () => import ('@/tba/views/invoices/InvoiceCreateIndex.vue')
const  InvoiceCreateSimple = () => import ('@/tba/views/invoices/InvoiceCreateSimple.vue')
const  InvoiceDetails = () => import ('@/tba/views/invoices/InvoiceDetails.vue')

const  AccountIndex = () => import ('@/tba/views/accounts/AccountIndex.vue')

const  SettingIndex = () => import ('@/tba/views/settings/SettingIndex.vue')
const  InvoiceSetting = () => import ('@/tba/views/settings/InvoiceSetting.vue')
const  BankSetting = () => import ('@/tba/views/settings/BankSetting.vue')
const  AuthMcfSetting = () => import ('@/tba/views/settings/AuthMcfSetting.vue')
const  SettingLayout = () => import ('@/tba/views/settings/SettingLayout.vue')

const  SubscriptionLayout = () => import ('@/tba/views/subscriptions/SubscriptionLayout')
const  SubscriptionPage = () => import ('@/tba/views/subscriptions/SubscriptionPage')
const  SubscriptionPack = () => import ('@/tba/views/subscriptions/SubscriptionPack')
// const  SubscriptionCreate = () => import ('@/tba/views/subscriptions/SubscriptionCreate')

const  Profile = () => import ('@/tba/views/profile/Profile.vue')
const  PaymentMethod = () => import ('@/tba/views/payment-invoice/PaymentInvoiceIndex.vue')
const  ModelLay = () => import ('@/tba/views/model_invoices/Model')
const  MarketPlaceModels = () => import ('@/tba/views/model_invoices/MarketPlaceModels')
const  MyModels = () => import ('@/tba/views/model_invoices/MyModels')

const  PdfIndex = () => import ('@/tba/views/pdf/PdfIndex')
const  ChartIndex = () => import ('@/components/dashboards/v-1')
const  Overviews = () => import ('@/tba/views/overviews/Overviews')

const  ActivitiesReports = () => import ('@/tba/views/reports/ActivitiesReports.vue')

const  InscriptionIndex = () => import ('@/tba/views/inscriptions/InscriptionIndex.vue')
const  CommercialIndex = () => import ('@/tba/views/commercials/CommercialIndex.vue')

export default [
    {
        path: '/',
        component:BackLayout,
        children:[
          {
            path: '',
            name: 'Tableau de bord',
            component:Home,
            meta:{
              auth:true,
              role:[
                "super-admin",
                "admin",
                "user"
              ]
            }
          },
          {
            path: '/depots',
            name: 'Dépots',
            component:DepotIndex,
            meta:{
              auth:true,
              roleperm:[
                "view-any::depot",
              ]
            },
          },
          {
            path: '/admin-tec/categories',
            name: 'Catégories',
            component:CategoryIndex,
            meta:{
              auth:true,
              roleperm:[
                "view-any::depot",
              ]
            },
          },
          {
            path: '/sites',
            name: 'Sites',
            component:SiteIndex,
            meta:{
              auth:true,
              roleperm:[
                "view-any::site",
              ]
            },
          },
          {
            path: '/admin-tec/modele-factures',
            name: 'Modele Factures',
            component:ModelLay,
            meta:{
              auth:true,
              role:[
                "super-admin",
              ]
            },
          },
          {
            path: '/structures-prix',
            name: 'Structures de prix',
            component:PriceIndex,
            meta:{
              auth:true,
              roleperm:[
                "view-any::price",
              ]
            },
          },
          {
            path: '/place-marche',
            name: 'Place de marché',
            component:MarketPlaceModels,
            meta:{
              auth:true,
              roleperm:[
                "view-any::setting"
              ]
            },
          },
          {
            path: '/mes-modeles',
            name: 'Mes modèles',
            component:MyModels,
            meta:{
              auth:true,
              roleperm:[
                "view-any::setting"
              ]
            },
          },
          {
            path: '/reports/activities',
            name: 'Rapport d\'activité',
            component:ActivitiesReports,
            meta:{
              auth:true,
              role:[
                "super-admin",
                "admin",
                "user",
              ],
              roleperm:[
                "view::activity-report"
              ]
            },
          },
          {
            path: '/reports/quantities',
            name: 'Rapport Quantité vendue',
            redirect:"/reports/quantities/article",
            component:() => import('@/tba/views/reports/QuantitiesSoldReports/QuantitiesLayout'),
            children:[
              {
                path: 'article',
                name: 'Rapport Quantité vendue',
                component:() => import('@/tba/views/reports/QuantitiesSoldReports/QuantitiesSoldByArticle'),
                meta:{
                  auth:true,
                sub: 'Quantité vendue par article',
                  role:[
                    "super-admin",
                    "admin",
                    'user'
                  ],
                  roleperm:[
                    "view::quantity-sold-report"
                  ]
                },
              },
              {
                path: 'article/site',
                name: 'Rapport Quantité vendue',
                component:() => import('@/tba/views/reports/QuantitiesSoldReports/QuantitiesSoldByArticleBySite'),
                meta:{
                  auth:true,
                sub: 'Quantité vendue par article et par site',

                  role:[
                    "super-admin",
                    "admin",
                    'user'
                  ],
                  roleperm:[
                    "view::quantity-sold-report"
                  ]
                },
              },
            ]
          },
          {
            path:"/reports/invoices",
            name:"Rapport Factures",
            component:() => import('@/tba/views/reports/Invoices/InvoiceReports'),
            meta:{
              auth:true,
              role:[
                "super-admin",
                "admin",
                'user'
              ],
              roleperm:[
                "view::invoice-report"
              ]
            },
          },
          {
            path: '/reports/sales_revenues',
            name: 'Rapport Chiffre d\'affaire',
            redirect:"/reports/sales_revenues_by_site",
            component:() => import('@/tba/views/reports/SalesFigures/SalesFigures'),
            children:[
              {
                path: '/reports/sales_revenues_by_site',
                name: 'Rapport Chiffre d\'affaire',
                component:() => import('@/tba/views/reports/SalesFigures/SalesFiguresBySite'),
                meta:{
                  auth:true,
                  sub: 'Chiffre d\'affaire par site',
                  role:[
                    "super-admin",
                    "admin",
                    'user'
                  ],
                  roleperm:[
                    "view::sale-report"
                  ]
                },
              },
              {
                path: '/reports/sales_revenues_by_user',
                name: 'Rapport Chiffre d\'affaire ',
                component:() => import('@/tba/views/reports/SalesFigures/SalesFiguresByUser'),
                meta:{
                  auth:true,
                  sub: 'Chiffre d\'affaire par utilisateur',

                  role:[
                    "super-admin",
                    "admin",
                    'user'
                  ],
                  roleperm:[
                    "view::sale-report"
                  ]
                },
              },
            ]
          },
          {
            path: '/admin-tec/paiements-type',
            name: 'Paiements & Type Facture',
            component:PaymentMethod,
            meta:{
              auth:true,
              role:[
                "super-admin",
              ]
            },
          },
          {
            path: '/clients',
            name: 'Clients',
            component:CustomerIndex,
            meta:{
              auth:true,
              roleperm:[
                "view-any::customer"
              ]
            },
          },
          {
            path: '/credit-plafond-customers',
            name: 'Ventes à crédit',
            component: () => import ('@/tba/views/credit-plafond-customers/CreditPlafondCustomerIndex.vue') ,
            meta:{
              auth:true,
              roleperm:[
                "view-any::credit-plafond-customer"
              ]
            },
          },
          {
            path: '/ventes-credit',
            name: 'Ventes à crédit',
            component: () => import ('@/tba/views/credit-sales/CreditSaleIndex.vue') ,
            meta:{
              auth:true,
              roleperm:[
                "view-any::credit-sale"
              ]
            },
          },
          {
            path: '/remboursements',
            name: 'Remboursements de dettes',
            component: () => import ('@/tba/views/debt-repayments/DebtRepaymentIndex.vue') ,
            meta:{
              auth:true,
              roleperm:[
                "view-any::debt-repayment"
              ]
            },
          },
          {
            path: '/utilisateurs/clients',
            name: 'Clients',
            component:CustomerUserIndex,
            meta:{
              auth:true,
              role:[
                "user",
              ],
              roleperm:[
                "view-any::customer"
              ]
            },
          },
          {
            path: '/roles',
            name: 'Rôles',
            component:RoleIndexVue,
            meta:{
              auth:true,
              roleperm:[
                "view-any::role",
              ]
            },
          },
          {
            path: '/utilisateurs',
            name: 'Utilisateurs',
            component:UserIndex,
            meta:{
              auth:true,
              roleperm:[
                "view-any::user"
              ]
            },
          },
          {
              path: '/articles',
              name: 'Articles',
              component:ArticleIndex,
              meta:{
                auth:true,
                roleperm:[
                  "view-any::article"
                ]
              },
          },
          {
            path: '/factures',
            component:InvoiceLayout,
            children:[
              {
                path: '/factures',
                name: 'Factures',
                component:InvoiceIndex,
                meta:{
                  auth:true,
                  roleperm:[
                    "view-any::invoice"
                  ]
                },
              },
              {
                path: 'creer',
                name: 'Creer Facture',
                component:InvoiceCreateIndex,
                meta:{
                  auth:true,
                  roleperm:[
                    "create::invoice"
                  ]
                },
              },
              {
                path: 'creer',
                name: 'Creer Facture Simple',
                component:InvoiceCreateSimple,
                meta:{
                  auth:true,
                  roleperm:[
                    "create::invoice"
                  ]
                },
              },
              {
                path: 'creer/annulation/:id',
                name: 'Creer Facture Avoir',
                component:() => import ('@/tba/views/invoices/InvoiceCreateAvoirIndex.vue'),
                meta:{
                  auth:true,
                  roleperm:[
                    "create::invoice"
                  ]
                },
              },
              {
                path: 'details/:id',
                name: 'Détail Facture',
                component:InvoiceDetails,
                meta:{
                  auth:true,
                  roleperm:[
                    "view::invoice",
                    "create::invoice"
                  ]
                },
              },
              {
                path: 'recherches',
                name: 'Recherche Facture',
                component:InvoiceSearch,
                meta:{
                  auth:true,
                  role:[
                    "super-admin",
                    "admin",
                    "user"
                  ]
                },
              }
            ]
            
          },
          {
            path: '/admin-tec/categorie-comptable',
            name: 'Catégorie Comptable',
            component:AccountIndex,
            meta:{
              auth:true,
              role:[
                "super-admin",
                "admin",
              ]
            },
          },
          {
            path: '/parametres',
            component:SettingLayout,
            children:[
              {
                path: '',
                name: 'Parametres',
                component:SettingIndex,
                meta:{
                  auth:true,
                  roleperm:[
                    "view-any::setting",
                    "edit::setting",
                  ]
                },
              },
              {
                path: 'invoices',
                name: 'Parametres Factures',
                component:InvoiceSetting,
                meta:{
                  auth:true,
                  roleperm:[
                    "view-any::setting",
                    "edit::setting",
                  ]
                },
              },
              {
                path: 'banks',
                name: 'Parametres Banques',
                component:BankSetting,
                meta:{
                  auth:true,
                  roleperm:[
                    "view-any::setting",
                    "edit::setting",
                  ]
                },
              },
              {
                path: 'notifications',
                name: 'Parametres Notifications',
                component: () => import ('@/tba/views/settings/NotifSetting.vue'),
                meta:{
                  auth:true,
                  roleperm:[
                    "view-any::setting",
                    "edit::setting",
                  ]
                },
              },
              {
                path: 'auth-emecef',
                name: 'Jeton de Connexion',
                component:AuthMcfSetting,
                meta:{
                  auth:true,
                  roleperm:[
                    "view-any::setting",
                    "edit::setting",
                  ]
                },
              },
              {
                path: 'subscriptions',
                name: 'Subscriptions',
                component:() => import ('@/tba/views/settings/SubSetting.vue'),
                meta:{
                  auth:true,
                  roleperm:[
                    "view-any::setting",
                    "edit::setting",
                  ]
                },
              },

              {
                path: 'storage',
                name: 'Storage',
                component:() => import ('@/tba/views/settings/Storage.vue'),
                meta:{
                  auth:true,
                  roleperm:[
                    "view-any::setting",
                    "edit::setting",
                  ]
                },
              },
              {
                path: 'storage/pricing',
                name: 'Storage Pricing',
                component:() => import ('@/tba/views/settings/StoragePricingSetting.vue'),
                meta:{
                  auth:true,
                  roleperm:[
                    "view-any::setting",
                    "edit::setting",
                  ]
                },
              },
              {
                path: 'subscriptions/pricing',
                name: 'Subscriptions',
                component:() => import ('@/tba/views/settings/SubPricingSetting.vue'),
                meta:{
                  auth:true,
                  roleperm:[
                    "view-any::setting",
                    "edit::setting",
                  ]
                },
              },
            ]
          },
          {
            path: '/mon-compte',
            name: 'Mon Compte',
            component:Profile,
            meta:{
              auth:true,
              role:[
                "super-admin",
                "admin",
                "user"
              ]
            },
          },
          {
            path: '/admin-tec/abonnements',
            component:SubscriptionLayout,
            children:[
              {
                path: '/admin-tec/abonnements',
                name: 'Abonnements',
                component:SubscriptionPage,
                meta:{
                  auth:true,
                  role:[
                    "super-admin",
                    "admin",
                  ]
                },
              },
              {
                path: '/admin-tec/tarifs',
                name: 'Tarifs',
                component:SubscriptionPack,
                meta:{
                  auth:true,
                  role:[
                    "super-admin",
                    "admin",
                  ]
                },
              },
              // {
              //   path: '/admin-tec/creer-abonnement',
              //   name: 'Creer Abonnement',
              //   component:SubscriptionCreate,
              //   meta:{
              //     auth:true,
              //     role:[
              //       "super-admin",
              //       "admin",
              //     ]
              //   },
              // },
            ]
          },
          {
            path: '/admin-tec/inscriptions',
            name: 'Inscriptions',
            component:InscriptionIndex,
            meta:{
              auth:true,
              role:[
                "super-admin",
              ]
            },
          },

          
          {
            path: '/admin-tec/commerciaux',
            name: 'Commerciaux',
            component:CommercialIndex,
            meta:{
              auth:true,
              role:[
                "super-admin",
              ]
            },
          },
          {
            path: '/admin-tec/entreprises',
            component:CompanyLayout,
            children:[
              {
                path: '',
                name: 'Entreprises',
                component:CompanyIndex,
                meta:{
                  auth:true,
                  role:[
                    "super-admin",
                  ]
                },
              }
            ]
          },
          {
            path: '/charts',
            name: 'Charts',
            component:ChartIndex,
            meta:{
              auth:false,
              role:[
                "super-admin",
                "admin",
              ]
            },
          },
          {
            path: '/vues-ensemble',
            name: 'Vues d\'ensemble',
            component:Overviews,
            meta:{
              auth:true,
              role:[
                "super-admin",
                "admin",
              ]
            },
          },
        ]
    },
    {
        path:'/login',
        component: FrontLayout,
        redirect:"Login Page",
        children:[
          {
            path: '',
            name: 'Login Page',
            component: LoginPage,
            meta:{
              auth:false
            },
          },
        ]
    },
    {
      path:'/locked',
      component: FrontLayout,
      redirect:"Lock Page",
      children:[
        {
          path: '',
          name: 'Lock Page',
          component: LockIndex,
          meta:{
            auth:true
          },
        },
      ]
  },

    {
      path:'/print-pdf/:template/:id',
      redirect: 'Print PDF',
      component: FrontLayout, 
      children:[
        {
          path:"",
          name: 'Print PDF',
          component: PdfIndex,
          meta:{
            auth:false,
            role:[
              "super-admin",
              "admin",
              "user",
            ]
          },
        }
      ]
    },

    {
      path:'/forgot-password',
      component: FrontLayout,
      redirect:"Forgot Page",
      children:[
        {
          path: '',
          name: 'Forgot Page',
          component: ForgotPasswordPage,
          meta:{
            auth:false
          },
        },
        {
          path: '/set-pwd/token/:token',
          name: 'Change Password Page',
          component: ChangePasswordPage,
          meta:{
            auth:false
          },
        },
      ]
    },

    {
      path: '/403',
      component: FrontLayout,
      children:[
          {
              path: '', 
              component: Forbiden,
              name:"forbidden",
              meta:{
                  auth:false,
              }
          },
      ]
    },

    //Page Not Found
    {
        path: '/*',
        component: FrontLayout,
        children:[
            {
                path: '', 
                component: NotFound,
                name:"notfound",
                meta:{
                  auth:false,
                }
            },
        ]
    },
]