import axios from "../../services/fetch"

export default {
    namespaced: true,
    state:{
        errors:{
            type:"",
            nature:"",
            ref:"",
        }
    },
    getters: {},
    mutations:{
        SET_TYPE(state, data) {
            
            if (data !== undefined) {
                state.errors.type = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.type = data
            }
        },
        SET_NATURE(state, data) {
            
            if (data !== undefined) {
                state.errors.nature = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.nature = data
            }
        },
        SET_REF(state, data) {
            
            if (data !== undefined) {
                state.errors.ref = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.ref = data
            }
        },
        SET_CLEAN(state){
            state.errors.type = "";
            state.errors.nature = "";
            state.errors.ref = "";
        }
    },
    actions:{
        /**
         * 
         * @param  param0 
         * @return response
         */
        async findAll({dispatch}){
        dispatch
            let response = await axios.get('/type_invoices');
            return response
        },

        /**
         * 
         * @param {*} param0 
         * @param Integer id 
         */
        async findById({dispatch},id){
        dispatch
            let response = await axios.get('/type_invoices/'+id)
            return response
        },

        /**
         *  Store 
         * @param {*} param0 
         * @param {*} data 
         */
        async save({dispatch},data){
        dispatch
            let response = await axios.post('/type_invoices',data);
            return response
        },

        /**
         * 
         * @param {*} param0 
         * @param {*} data 
         */
        async update({dispatch},data){
        dispatch
            let response = await axios.put(`/type_invoices/${data.id}`,data);
            return response
        },

        /**
         * 
         * @param {*} param0 
         * @param {*} id 
         */
        async delete({dispatch},id){
        dispatch
            let response = await axios.delete(`/type_invoices/${id}`);
            return response
        }

    }
  }
  