import axios from "../../services/fetch"

export default {
    namespaced: true,
    state:{
        errors:{
            "credit_plafond": "",
            "status": "",
            "validated_at": "",
            "validated_by": "",
            "customer_id": "",
            "company_id": "",
        }
    },
    getters: {},
    mutations:{
        SET_CP(state, data) {
            if (data !== undefined) {
                state.errors.credit_plafond = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.credit_plafond = data
            }
        },
        SET_CI(state, data) {
            if (data !== undefined) {
                state.errors.customer_id = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.customer_id = data
            }
        }, 

        SET_CLEAN(state){
            state.errors.credit_plafond = "";
            state.errors.customer_id = "";
        }
    },
    actions:{
        /**
         * @param  param0 
         * @return response
         */
        async findAll({dispatch},data){
        dispatch
            let response = await axios.get('/credit-plafond-customers?page='+data.page+'&limit='+data.perPage);
            return response
        },

        /**
         * @param {*} param0 
         * @param {*} data 
         */
        async update({dispatch},data){
        dispatch
            let response = await axios.put(`/credit-plafond-customers/${data.id}`,data);
            return response
        },

        /**
         * @param {*} param0 
         * @param {*} data 
         */
        async approbation({dispatch},data){
            dispatch
            let response = await axios.put(`/credit-plafond-customers/${data.id}?approbation=1`,data);
            return response
        },

        /**
         * @param {*} param0 
         * @param Integer id 
         */
        async findById({dispatch},id){
        dispatch
            let response = await axios.get('/credit-plafond-customers/'+id)
            return response
        },
        /**
         * @param {*} param0 
         * @param {*} data 
         */
        async save({dispatch},data){
        dispatch
            let response = await axios.post('/credit-plafond-customers',data);
            return response
        },

        /**
         * @param {*} param0 
         * @param {*} id 
         */
        async delete({dispatch},id){
        dispatch
            let response = await axios.delete(`/credit-plafond-customers/${id}`);
            return response
        }
    
        

    }
  }
  