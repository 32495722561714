import axios from "../../services/fetch"

export default {
    namespaced: true,
    state:{
        errors:{
        }
    },
    getters: {},
    mutations:{
        
    },
    actions:{
        /**
         * 
         * @param  param0 
         * @return response
         */
        async findAll({dispatch}){
        dispatch
            let response = await axios.get('/subscriptions');
            return response
        },

        async findByCompany({dispatch},data){
        dispatch
            let response = await axios.get('/subscriptions/company/'+data.id);
            return response
        },

        /**
         * 
         * @param {*} param0 
         * @param Integer id 
         */
        async findById({dispatch},id){
        dispatch
            let response = await axios.get('/subscriptions/'+id)
            return response
        },

        /**
         * 
         * @param {*} param0 
         * @param Integer id 
         */
         async getSubBill({dispatch},id){
            dispatch
                let response = await axios.get(`/subscriptions/${id}?r=1`)
                return response
            },

        /**
         *  Store 
         * @param {*} param0 
         * @param {*} data 
         */
        async save({dispatch},data){
        dispatch
            let response = await axios.post('/subscriptions',data);
            return response
        },

        /**
         * 
         * @param {*} param0 
         * @param {*} id 
         */
        async delete({dispatch},id){
        dispatch
            let response = await axios.delete(`/subscriptions/${id}`);
            return response
        }

    }
  }
  