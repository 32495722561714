import axios from "../../services/fetch"

export default {
    namespaced: true,
    state:{
        errors:{
            category:"",
        }
    },
    getters: {},
    mutations:{
        SET_CATEGORY(state, data) {
            
            if (data !== undefined) {
                state.errors.category = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.category = data
            }
        },
        SET_CLEAN(state){
            state.errors.category = "";
        }
    },
    actions:{
        /**
         * 
         * @param  param0 
         * @return response
         */
        async findAll({dispatch}){
        dispatch
            let response = await axios.get('/accounting_categories');
            return response
        },

        /**
         * 
         * @param {*} param0 
         * @param Integer id 
         */
        async findById({dispatch},id){
        dispatch
            let response = await axios.get('/accounting_categories/'+id)
            return response
        },

        /**
         *  Store 
         * @param {*} param0 
         * @param {*} data 
         */
        async save({dispatch},data){
        dispatch
            let response = await axios.post('/accounting_categories',data);
            return response
        },

        /**
         * 
         * @param {*} param0 
         * @param {*} data 
         */
        async update({dispatch},data){
        dispatch
            let response = await axios.put(`/accounting_categories/${data.id}`,data);
            return response
        },

        /**
         * 
         * @param {*} param0 
         * @param {*} id 
         */
        async delete({dispatch},id){
        dispatch
            let response = await axios.delete(`/accounting_categories/${id}`);
            return response
        }

    }
  }
  