import axios from "../../services/fetch"

export default {
    namespaced: true,
    state:{
        errors:{
            code_mcf:"",
            moyens:"",
        }
    },
    getters: {},
    mutations:{
        SET_MOYEN(state, data) {
            
            if (data !== undefined) {
                state.errors.moyens = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.moyens = data
            }
        },
        SET_CODE(state, data) {
            
            if (data !== undefined) {
                state.errors.code_mcf = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.code_mcf = data
            }
        },
        SET_CLEAN(state){
            state.errors.moyens = "";
            state.errors.code_mcf = "";
        }
    },
    actions:{
        /**
         * 
         * @param  param0 
         * @return response
         */
        async findAll({dispatch}){
        dispatch
            let response = await axios.get('/payment_methods');
            return response
        },

        /**
         * 
         * @param {*} param0 
         * @param Integer id 
         */
        async findById({dispatch},id){
        dispatch
            let response = await axios.get('/payment_methods/'+id)
            return response
        },

        /**
         *  Store 
         * @param {*} param0 
         * @param {*} data 
         */
        async save({dispatch},data){
        dispatch
            let response = await axios.post('/payment_methods',data);
            return response
        },

        /**
         * 
         * @param {*} param0 
         * @param {*} data 
         */
        async update({dispatch},data){
        dispatch
            let response = await axios.put(`/payment_methods/${data.id}`,data);
            return response
        },

        /**
         * 
         * @param {*} param0 
         * @param {*} id 
         */
        async delete({dispatch},id){
        dispatch
            let response = await axios.delete(`/payment_methods/${id}`);
            return response
        }

    }
  }
  