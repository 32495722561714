import lock from "./lock"
import swal from "./swal"
import setting from "./setting"
import site from "./site"
import subscription from "./subscription"
import subscription_pack from "./subscription_pack"
import storage_pack from "./storage_pack"
import subscription_space from "./subscription_space"
import company from "./company"
import auth from "./auth"
import user from "./user"
import article from "./article"
import depot from "./depot"
import account_c from "./accounting_category"
import customer from "./customer"
import price from "./price"
import payment from "./payment_method"
import type_invoice from "./type_invoice"
import invoice from "./invoice"
import model_invoice from "./model_invoice"
import my_model from "./my_model"
import report from "./report"
import commercial from "./commercial"
import enrolment from "./enrolment"
import debt_repayment from "./debt_repayment"
import credit_sale from "./credit_sale"
import credit_plafond_customer from "./credit_plafond_customer"
import role from "./role"
import permission from "./permission"

export default  {
  auth,
  company,
  setting,
  swal,
  site,
  user,
  article,
  depot,
  account_c,
  customer,
  price,
  payment,
  type_invoice,
  invoice,
  model_invoice,
  report,
  subscription_pack,
  storage_pack,
  subscription_space,
  debt_repayment,
  credit_sale,
  subscription,
  commercial,
  enrolment,
  lock,
  credit_plafond_customer,
  my_model,
  role,
  permission,
}