import axios from './fetch';
import store from '../store/index'
import router from '../router/index'
import Lock from './system-lock/Lock';

axios.interceptors.request.use(
    config => {
        const token = localStorage.getItem('token');
        if(token){
            config.headers['Authorization'] = 'Bearer '+token;
        }
        return config;
    },
    error => {
        return new Promise.reject(error)
    });

axios.interceptors.response.use( (response) =>{
        return response;
    },(error) => { 

        switch (store.state.auth.connected) {
            case true:
                if(error.response.status === 401){
                    store.commit('auth/SET_TOKEN', null)
                    localStorage.removeItem('token')
                    store.commit('auth/SET_CONNECTED', false)
                    router.replace({name:"Login Page"});
                    return Promise.reject(error);
                }
                if (error.response.status === 400) {
                    if (error.response.data.message === "Compte verrouillé apres inactivité") {
                        Lock.lock();
                        return Promise.reject(new Error('Compte Verrouillé'))
                    }
                }

                if (error.response.status === 422) {
                    if (error.response.data.message === 'The given data was invalid.') {
                        error.response.data.message = "Les données fournies ne sont pas valides."
                        return Promise.reject(error)
                    }
                }
                
                break;
        
            default:
                break;
        }
        return  Promise.reject(error);
    });

export default axios