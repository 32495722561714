<template>
  <div id="app" class="dark">
    <notifications></notifications>
    <nprogress-container></nprogress-container>
    <!--MainContent-->
    <main>
      <div>
        <router-view></router-view>
      </div>
    </main>
  </div>
</template>

<script>
import NprogressContainer from "vue-nprogress/src/NprogressContainer";
import "@/assets/scss/notifyjs/material.scss";
export default {
  name: "App",
  components: {
    NprogressContainer,
  },
  data() {
    return {
      //
    };
  },
  methods:{
 
  },
  mounted() {
    this.$loadScript("https://cdn.kkiapay.me/k.js");
        
    this.$notifications.setOptions({
      type: "primary",
      timeout: 5000,
      horizontalAlign: "left",
      verticalAlign: "bottom",
    });

    const loader = document.querySelector(".preloader");
    setTimeout(() => {
      loader.style.opacity = 0;
      loader.style.display = "none";
    }, 2000);

    // var newGuirland1 = document.createElement("div");
    // var newGuirland2 = document.createElement("div");
    // var newGuirland3 = document.createElement("div");
    // newGuirland1.setAttribute("class", "guirlande guirlande1");
    // newGuirland2.setAttribute("class", "guirlande guirlande2");
    // newGuirland3.setAttribute("class", "guirlande guirlande3");
    
    // document.body.appendChild(newGuirland1)
    // document.body.appendChild(newGuirland2)
    // document.body.appendChild(newGuirland3)

  },
  updated() {},
};
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap");

html {
  font-size: 16px;
  width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
button,
a,
ul,
li {
  font-family: "Roboto", "Arial", "Calibri";
}

main {
  min-height: 90vh;
}

.fixed-sn footer {
  padding-left: 0;
}

.side-nav .logo-sn {
  padding-bottom: 1rem;
  padding-top: 1rem;
  border-bottom: 1px solid rgba(153, 153, 153, 0.3);
}

.navbar .btn,
.navbar .nav-item,
.navbar .nav-link {
  padding: 0;
}

.list-group .ml-auto {
  float: right;
  flex: none !important;
}

.datepicker-overlay {
  z-index: 9999 !important;
}

.navbar-brand {
  cursor: pointer;
}

/*  */

.guirlande{
    pointer-events: none;
    z-index: 10110;
    width: 100%;
    height: 150px;
    position: absolute;
    top: 150px;
    left: 0;
}

.guirlande.guirlande1 {
    height: 150px;
    background: url(../src/assets/img/guirlande1.png) repeat-x 300% top;
    top: -23px;
}
.guirlande.guirlande2 {
    background: url(../src/assets/img/guirlande2.png) repeat-x 70% top;
    top: -71px;
}
.guirlande.guirlande3 {
    background: url(../src/assets/img/guirlande3.png) repeat-x 10% top;
    top: -51px;
}
</style>
