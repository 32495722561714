export default {
    namespaced: true,
    state:{
        timer:5,
        step:10,
        locked:false,
    },
    getters: {},
    mutations:{
        SET_TIME_DECREMENT: (state, value) => {
            state.timer -= value;
        },
        
        SET_ZERO: (state) => { 
            state.timer = state.step;
        },

        SET_TIMER_STEP: (state,value) => { 
            state.timer = value;
            state.step = value;
        },

        SET_LOCK: (state, value) => { 
            state.locked = value;
        },
        SET_RESET: (state) => { 
            state.timer = state.step;
        },
    },
    actions:{
        reset(context){
            context.commit('SET_RESET')
        },
        resetUltime(context,value){
            context.commit('SET_TIMER_STEP',value)
        }
    }
  }
  