import axios from "../../services/fetch"

export default {
    namespaced: true,
    state:{
        errors:{
            ref:"",
            invoice_index:"",
            code_invoice_index:"",
            family:"",
            tva:"",
            puht:"",
            designation:"",
        }
    },
    getters: {},
    mutations:{
        SET_FAMILY(state, data) {
            
            if (data !== undefined) {
                state.errors.family = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.family = data
            }
        },
        SET_UNIT(state, data) {
            
            if (data !== undefined) {
                state.errors.unit = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.unit = data
            }
        },

        SET_ININDEX(state, data) {
            
            if (data !== undefined) {
                state.errors.invoice_index = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.invoice_index = data
            }
        },

        SET_PUHT(state, data) {
            
            if (data !== undefined) {
                state.errors.puht = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.puht = data
            }
        },

        SET_TVA(state, data) {
            
            if (data !== undefined) {
                state.errors.tva = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.tva = data
            }
        },

        SET_DESIGNATION(state, data) {
            
            if (data !== undefined) {
                state.errors.designation = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.designation = data
            }
        },
        SET_REF(state, data) {
            
            if (data !== undefined) {
                state.errors.ref = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.ref = data
            }
        },
        SET_CLEAN(state){
            state.errors.invoice_index = "";
            state.errors.ref = "";
            state.errors.family = "";
            state.errors.designation = "";
            state.errors.puht = "";
            state.errors.tva = "";
            state.errors.unit = "";
        }
    },
    actions:{
        /**
         * 
         * @param  param0 
         * @return response
         */
        async findAll({dispatch}){
        dispatch
            let response = await axios.get('/articles');
            return response
        },

        async findAllOnlySpecific({dispatch}){
            dispatch
            let response = await axios.get('/articles?specific=1');
            return response
        },

        /**
         * 
         * @param {*} param0 
         * @param Integer id 
         */
        async findById({dispatch},id){
        dispatch
            let response = await axios.get('/articles/'+id)
            return response
        },

        /**
         *  Store 
         * @param {*} param0 
         * @param {*} data 
         */
        async save( {dispatch}, data){
        dispatch
            let response = await axios.post('/articles',data);
            return response
        },

        /**
         * 
         * @param {*} param0 
         * @param {*} data 
         */
        async update( {dispatch}, data){
        dispatch
            let response = await axios.put(`/articles/${data.id}`,data);
            return response
        },

        /**
         * 
         * @param {*} param0 
         * @param {*} id 
         */
        async delete({dispatch}, id){
        dispatch
            let response = await axios.delete(`/articles/${id}`);
            return response
        }

    }
  }
  