import { render, staticRenderFns } from "./RoleIndex.vue?vue&type=template&id=671a1b8c&scoped=true&"
import script from "./RoleIndex.vue?vue&type=script&lang=js&"
export * from "./RoleIndex.vue?vue&type=script&lang=js&"
import style0 from "./RoleIndex.vue?vue&type=style&index=0&id=671a1b8c&prod&lang=css&"
import style1 from "./RoleIndex.vue?vue&type=style&index=1&id=671a1b8c&prod&lang=css&"
import style2 from "./RoleIndex.vue?vue&type=style&index=2&id=671a1b8c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.10.0_cache-loader@4.1.0_css-loader@3.6.0_vue-template-compiler@2.7.10_webpack@4.46.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "671a1b8c",
  null
  
)

export default component.exports