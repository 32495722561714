import axios from "../../services/fetch"

export default {
    namespaced: true,
    state:{
        errors:{
            code_client:"",
            num_contrat:"",
            designation:"",
            ifu:"",
            email:"",
        }
    },
    getters: {},
    mutations:{
        SET_CODE(state, data) {
            
            if (data !== undefined) {
                state.errors.code_client = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.code_client = data
            }
        },

        SET_CONTRAT(state, data) {
            
            if (data !== undefined) {
                state.errors.num_contrat = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.num_contrat = data
            }
        },
        SET_DESI(state, data) {
            if (data !== undefined) {
                state.errors.designation = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.designation = data
            }
        },

        SET_IFU(state, data) {
            if (data !== undefined) {
                state.errors.ifu = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.ifu = data
            }
        },
        SET_EMAIL(state, data) {
            if (data !== undefined) {
                state.errors.email = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.email = data
            }
        },
        SET_CLEAN(state){
            state.errors.designation = "";
            state.errors.num_contrat = "";
            state.errors.ifu = "";
            state.errors.email = "";
            state.errors.code_client = "";
        }
    },
    actions:{
        /**
         * 
         * @param  param0 
         * @return response
         */
        async findAll({dispatch}){
        dispatch
            let response = await axios.get('/customers');
            return response
        },

        /**
         * 
         * @param {*} param0 
         * @param Integer id 
         */
        async findById({dispatch},id){
        dispatch
            let response = await axios.get('/customers/'+id)
            return response
        },

        /**
         *  Store 
         * @param {*} param0 
         * @param {*} data 
         */
        async save({dispatch},data){
        dispatch
            let response = await axios.post('/customers',data);
            return response
        },

        /**
         * 
         * @param {*} param0 
         * @param {*} data 
         */
        async update({dispatch},data){
        dispatch
            let response = await axios.put(`/customers/${data.id}`,data);
            return response
        },

        /**
         * 
         * @param {*} param0 
         * @param {*} data 
         */
         async getCreditPlafond({dispatch},data){
            dispatch
                let response = await axios.post(`/customers/${data}/credit-plafond`);
                return response
            },
    

        /**
         * 
         * @param {*} param0 
         * @param {*} id 
         */
        async delete({dispatch},id){
        dispatch
            let response = await axios.delete(`/customers/${id}`);
            return response
        }

    }
  }
  