import Vue from 'vue'

import lang from 'element-ui/lib/locale/lang/fr'
import locale from 'element-ui/lib/locale'

// configure la langue
locale.use(lang)


import {
    Dropdown,DropdownMenu,DropdownItem,
    DatePicker,Button, ColorPicker, Upload, Tooltip,
    Steps,Step, Loading, Checkbox, CheckboxGroup, 
    } from 'element-ui'
    
Vue.component(Dropdown.name, Dropdown) 
Vue.component(DropdownItem.name, DropdownItem) 
Vue.component(DropdownMenu.name, DropdownMenu) 
Vue.component(Step.name, Step) 
Vue.component(Steps.name, Steps) 
Vue.component(Tooltip.name, Tooltip) 
Vue.component(ColorPicker.name, ColorPicker) 
Vue.component(Upload.name, Upload) 
Vue.component(Button.name, Button) 
Vue.component(Dropdown.name, Dropdown) 
Vue.component(DropdownItem.name, DropdownItem) 
Vue.component(DropdownMenu.name, DropdownMenu) 
Vue.component(DatePicker.name, DatePicker) 
Vue.component(Checkbox.name, Checkbox) 
Vue.component(CheckboxGroup.name, CheckboxGroup) 

Loading.install(Vue)
