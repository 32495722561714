import axios from "../../services/fetch"
import auth from './auth'
export default {
    namespaced: true,
    state:{
        errors:{
            num_facture:"",
            date_facture:"",
            type:"",
            depot_id:"",
            customer_id:"",
            accounting_category_id:"",
            payment_method_id:"",
        }
    },
    getters: {},
    mutations:{
        SET_NF(state, data) {
            if (data !== undefined) {
                state.errors.num_facture = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.num_facture = data
            }
        },
        SET_DF(state, data) {
            if (data !== undefined) {
                state.errors.date_facture = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.date_facture = data
            }
        },
        SET_TYPE(state, data) {
            if (data !== undefined) {
                state.errors.type = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.type = data
            }
        },
        SET_DI(state, data) {
            if (data !== undefined) {
                state.errors.depot_id = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.depot_id = data
            }
        },
        SET_CI(state, data) {
            if (data !== undefined) {
                state.errors.customer_id = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.customer_id = data
            }
        },
        SET_ACI(state, data) {
            if (data !== undefined) {
                state.errors.accounting_category_id = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.accounting_category_id = data
            }
        },
        SET_PMI(state, data) {
            if (data !== undefined) {
                state.errors.payment_method_id = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.payment_method_id = data
            }
        },
        SET_CLEAN(state){
            state.errors.num_facture = ""
            state.errors.date_facture = ""
            state.errors.type = ""
            state.errors.depot_id = ""
            state.errors.customer_id = ""
            state.errors.accounting_category_id = ""
            state.errors.payment_method_id = ""
        }
    },
    actions:{
        /**
         * @param  param0 
         * @return response
         */
        async findAll({dispatch},data){
        dispatch
            let response = await axios.get('/invoices?page='+data.page+'&limit='+data.perPage);
            return response
        },

        /**
         * @param  param0 
         * @return response
         */
         async search({dispatch},{q,page}){
        dispatch
            let response = await axios.get(`/search?q=${q}&page=${page}`);
            return response
        },

        async getTotalInvoice(){
            let response = await axios.get('/invoices?t=total');
            return response
        },

        async getInvoiceNormalise(){
            let response = await axios.get('/invoices?n=1');
            return response
        },

        /**
         * @param {*} param0 
         * @param Integer id 
         */
        async findById({dispatch},id){
        dispatch
            let response = await axios.get('/invoices/'+id)
            return response
        },
        

        /**
         *  Store 
         * @param {*} param0 
         * @param {*} data 
         */
        async save({dispatch},data){
        dispatch
            let response = await axios.post('/invoices',data);
            return response
        },

        async normalize({dispatch},id){
        dispatch
            let response = await axios.post('/invoices/normalize/'+id);
            return response
        },

        async sendInvoice({dispatch},data){
        dispatch
            let response = await axios.post('/invoices/send/'+data.invoice,data);
            return response
        },

        /**
         * 
         * @param {*} param0 
         * @param {*} data 
         */
        async update({dispatch},data){
        dispatch
            let response = await axios.put(`/invoices/${data.id}`,data);
            return response
        },

        /**
         * 
         * @param {*} param0 
         * @param {*} id 
         */
        async delete({dispatch},id){
        dispatch
            let response = await axios.delete(`/invoices/${id}`);
            return response
        },

        /**
         * delete all invoices
         * @param {*} param0 
         * @param {*} id 
         */
         async deleteAllInvoices({dispatch}){
            dispatch
            let response = await axios.post(`/invoices/multi-del?invnn=1`);
            return response
        },

        /**
         * 
         * @param {*} param0 
         * @param {*} id 
         */
         async deleteMulti({dispatch},factures){
                dispatch
                let response = await axios.post(`/invoices/multi-del`,factures);
                return response
            },

     
       
    },
    modules:{
        auth
    }
  }
  