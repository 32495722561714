import axios from "../../services/fetch"

export default {
    namespaced: true,
    state:{
        errors:{
            lastname:"",
            firstname:"",
            password:"",
            email:"",
            password_c:"",
            oldPassword:"",
        }
    },
    getters: {},
    mutations:{
        SET_LNAME(state, data) {
            if (data !== undefined) {
                state.errors.lastname = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.lastname = data
            }
        },
        SET_LFIRST(state, data) { 
            if (data !== undefined) {
                state.errors.firstname = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.firstname = data
            }
        },
        SET_EMAIL(state, data) {
            
            if (data !== undefined) {
                state.errors.email = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.email = data
            }
        },
        SET_PWD(state, data) {
            
            if (data !== undefined) {
                state.errors.password = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.password = data
            }
        },
        SET_OLD(state, data) {
            
            if (data !== undefined) {
                state.errors.oldPassword = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.oldPassword = data
            }
        },
        SET_PWDC(state, data) {
            
            if (data !== undefined) {
                state.errors.password_c = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.password_c = data
            }
        },
        SET_CLEAN(state){
            state.errors.lastname = "";
            state.errors.firstname = "";
            state.errors.password = "";
            state.errors.email = "";
            state.errors.password_c = "";
            state.errors.oldPassword = "";
        }
    },
    actions:{
        /**
         * @param  param0 
         * @return response
         */
        async findAll({dispatch}){
        dispatch
            let response = await axios.get('/users');
            return response
        },

        async findAllWAdmin({dispatch}){
        dispatch
            let response = await axios.get('/users?wa=1');
            return response
        },

        /**
         * @param {*} param0 
         * @param Integer id 
         */
        async findById({dispatch}, id){
        dispatch
            let response = await axios.get('/users/'+id)
            return response
        },

        /**
         *  Store 
         * @param {*} param0 
         * @param {*} data 
         */
        async save({dispatch}, data){
        dispatch
            let response = await axios.post('/users',data);
            return response
        },


        async update({dispatch}, data){
        dispatch
            let response = await axios.put(`/users/${data.id}`,data);
            return response
        },

        async updateProfile({dispatch}, data){
        dispatch
            let response = await axios.put(`/users/profile/${data.id}`,data);
            return response
        },

        /**
         * @param {*} param0 
         * @param {*} id 
         */
        async delete({dispatch}, id){
        dispatch
            let response = await axios.delete(`/users/${id}`);
            return response
        }, 

        async deleteAvatar({dispatch},id){
        dispatch
            let response = await axios.delete(`/users/avatar/${id}`);
            return response
        },

        async chpwd({dispatch}, data){
        dispatch
            let response = await axios.put(`/users/chpwd/${data.id}`,data);
            return response
        },

        async checkPassword({dispatch}, data){
        dispatch
            let response = await axios.post(`/check-password`,data);
            return response
        },

        async lockMe(){
            let response = await axios.post(`/auth/lock-me`);
            return response
        },
        async unLockMe({dispatch}, data){
        dispatch
            let response = await axios.post(`/auth/unlock-me`,data);
            return response
        },

        async sendIdentifier({dispatch}, data){
        dispatch
            let response = await axios.post(`/users/send-identifier/${data.id}`,data);
            return response
        },

    }
  }
  