import axios from "../../services/fetch"

export default {
    namespaced: true,
    state:{
        errors:{
            start_date:"",
            end_date:"",
            amount:"",
            ref:"",
            credit_sales:"",
            customer_id:"",
            bcc_debts:"",
        }
    },
    getters: {},
    mutations:{
        SET_SD(state, data) {
            if (data !== undefined) {
                state.errors.start_date = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.start_date = data
            }
        },
        SET_ED(state, data) {
            if (data !== undefined) {
                state.errors.end_date = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.end_date = data
            }
        },
        SET_AMOUNT(state, data) {
            if (data !== undefined) {
                state.errors.amount = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.amount = data
            }
        },
        SET_CI(state, data) {
            if (data !== undefined) {
                state.errors.customer_id = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.customer_id = data
            }
        },
        SET_CS(state, data) {
            if (data !== undefined) {
                state.errors.credit_sales = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.credit_sales = data
            }
        },
        SET_CLEAN(state){
            state.errors.start_date = "";
            state.errors.end_date = "";
            state.errors.amount = "";
            state.errors.credit_sales = "";
            state.errors.customer_id = "";
        }
    },
    actions:{
        /**
         * @param  param0 
         * @return response
         */
        async findAll({dispatch},data){
        dispatch
            let response = await axios.get('/debt-repayments?page='+data.page+'&limit='+data.perPage);
            return response
        },

        /**
         * @param {*} param0 
         * @param Integer id 
         */
        async findById({dispatch},id){
        dispatch
            let response = await axios.get('/debt-repayments/'+id)
            return response
        },

        /**
         * @param {*} param0 
         * @param {*} data 
         */
        async save({dispatch},data){
        dispatch
            let response = await axios.post('/debt-repayments',data);
            return response
        },

        /**
         * @param {*} param0 
         * @param {*} data 
         */
        async update({dispatch},data){
        dispatch
            let response = await axios.put(`/debt-repayments/${data.id}`,data);
            return response
        },

        /**
         * @param {*} param0 
         * @param {*} id 
         */
        async delete({dispatch},id){
        dispatch
            let response = await axios.delete(`/debt-repayments/${id}`);
            return response
        }

    }
  }
  