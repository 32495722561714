import axios from "../../services/fetch"

export default {
    namespaced: true,
    state:{
        errors:{
            name:"",
            nbr_pistol:"",
            nbr_tank:"",
        }
    },
    getters: {},
    mutations:{
        SET_NAME(state, data) {
            if (data !== undefined) {
                state.errors.name = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.name = data
            }
        },
        SET_PISTOL(state, data) {
            if (data !== undefined) {
                state.errors.nbr_pistol = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.nbr_pistol = data
            }
        },
        SET_TANK(state, data) {
            if (data !== undefined) {
                state.errors.nbr_tank = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.nbr_tank = data
            }
        },
        SET_CLEAN(state){
            state.errors.name = "";
            state.errors.nbr_tank = "";
            state.errors.nbr_pistol = "";
        }
    },
    actions:{
        /**
         * @param  param0 
         * @return response
         */
        async findAll({dispatch}){
        dispatch
            let response = await axios.get('/sites');
            return response
        },

        async findByCompany({dispatch},data){
        dispatch
            let response = await axios.get('/sites/company/'+data.id);
            return response
        },

        /**
         * @param {*} param0 
         * @param Integer id 
         */
        async findById({dispatch},id){
        dispatch
            let response = await axios.get('/sites/'+id)
            return response
        },

        /**
         *  Store 
         * @param {*} param0 
         * @param {*} data 
         */
        async save({dispatch},data){
        dispatch
            let response = await axios.post('/sites',data);
            return response
        },

        /**
         * 
         * @param {*} param0 
         * @param {*} data 
         */
        async update({dispatch},data){
        dispatch
            let response = await axios.put(`/sites/${data.id}`,data);
            return response
        },

        /**
         * 
         * @param {*} param0 
         * @param {*} id 
         */
        async delete({dispatch},id){
        dispatch
            let response = await axios.delete(`/sites/${id}`);
            return response
        }

    }
  }
  