import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import importModules from "./modules/import_modules"
import globalConfig from "../tstba.conf.js"

export default new Vuex.Store({
  state: {
    sidenav: true,
    url:(globalConfig.production_tip) ? globalConfig.tba_server_prod : globalConfig.tba_server_dev,
    theme:"theme-light",
    modeFooter:false,
    theme_tip:{
      input_class:`wrapperClass="card-reversed" inputClass="border-reversed color-normal-reversed" labelClass="card-reversed color-normal-reversed"`
    }
  },
  getters: {
    sidenav: state => state.sidenav,
  },
  mutations: {
    toggleSideNav: (state, value) => {
      if (typeof value === 'boolean') {
        state.sidenav = value;
        return;
      }
      state.sidenav = !state.sidenav;
    },

    switcherTheme: (state, value) => { 
      state.theme = value;
    },

    toggleFooterModeDark: (state, value) => { 
      state.modeFooter = value;
    }
  },
  actions: {
    toggleSideNav({commit}, value) {
      commit('toggleSideNav', value)
    }
  },
  modules: importModules
})
