import store from "@/store/index"
class Gate {
    static checkTypeRole(role) {

      if (role === null) {
        return false
      } 
              
      let result = false;
      if (typeof role  === 'object') {
        for (let i = 0; i < role.length; i++) {
          if (role[i] === store.state.auth.user.role) {
            result = true;
          }
        }
        return result;
      }

      if (typeof role  === "string") {
        return role === store.state.auth.user.role;
      }
    }

    static checkUser(user){
      return user === store.state.auth.user.id;
    }

    static hasPermission(permissions, strict = false) {
      let permissions_to_verify = [];
    
      if (permissions === null) {
        return false;
      }
    
      if (typeof role === "string") {
        permissions_to_verify = permissions.split(",");
      } else {
        permissions_to_verify = permissions;
      }
    
      if (strict) {
        let result = true;
        for (let i = 0; i < permissions_to_verify.length; i++) {
          if (!store.state.auth.user.permissions.includes(permissions_to_verify[i])) {
            result = false;
            break; // Exit the loop if any permission is missing
          }
        }
        return result;
      } else {
        let result = false;
        for (let i = 0; i < permissions_to_verify.length; i++) {
          if (store.state.auth.user.permissions.includes(permissions_to_verify[i])) {
            result = true;
            break; // Exit the loop if any permission is found
          }
        }
        return result;
      }
    }
    
}

export default Gate