import axios from "../../services/fetch"

export default {
    namespaced: true,
    state:{
        errors:{
            name:"",
            email:"",
            phone:"",
            ifu:"",
            rccm:"",
            address:"",
        }
    },
    getters: {},
    mutations:{
        SET_NAME(state, data) {
            
            if (data !== undefined) {
                state.errors.name = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.name = data
            }
        },
        SET_EMAIL(state, data) {
            
            if (data !== undefined) {
                state.errors.email = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.email = data
            }
        },
        SET_PHONE(state, data) {
            
            if (data !== undefined) {
                state.errors.phone = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.phone = data
            }
        },
        SET_IFU(state, data) {
            
            if (data !== undefined) {
                state.errors.ifu = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.ifu = data
            }
        },
        SET_RCCM(state, data) {
            
            if (data !== undefined) {
                state.errors.rccm = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.rccm = data
            }
        },
        SET_ADDR(state, data) {
            
            if (data !== undefined) {
                state.errors.address = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.address = data
            }
        },
        SET_CLEAN(state){
            state.errors.name = ""
            state.errors.email = ""
            state.errors.phone = ""
            state.errors.ifu = ""
            state.errors.rccm = ""
            state.errors.address = ""
        }
    },
    actions:{
        /**
         * 
         * @param  param0 
         * @return response
         */
        async findAll({dispatch}){
        dispatch
            let response = await axios.get('/companies');
            return response
        },

        /**
         * 
         * @param {*} param0 
         * @param Integer id 
         */
        async findById({dispatch},id){
        dispatch
            let response = await axios.get('/companies/'+id)
            return response
        },

        /**
         *  Store 
         * @param {*} param0 
         * @param {*} data 
         */
        async save({dispatch},data){
        dispatch
            let response = await axios.post('/companies',data);
            return response
        },

        /**
         * 
         * @param {*} param0 
         * @param {*} data 
         */
        async update({dispatch},data){
        dispatch
            let response = await axios.put(`/companies/${data.id}`,data);
            return response
        },

        /**
         * 
         * @param {*} param0 
         * @param {*} id 
         */
        async delete({dispatch},id){
        dispatch
            let response = await axios.delete(`/companies/${id}`);
            return response
        },

        async deleteLogo({dispatch},id){
        dispatch
            let response = await axios.delete(`/companies/logo/${id}`);
            return response
        }

    }
  }
  