import axios from "../../services/fetch"

export default {
    namespaced: true,
    state:{
        errors:{
            operation_date:"",
            amount:"",
            quantity:"",
            customer_id:"",
            article_id:"",
            matriculation:"",
        }
    },
    getters: {},
    mutations:{
        SET_OD(state, data) {
            if (data !== undefined) {
                state.errors.operation_date = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.operation_date = data
            }
        },
        SET_QTE(state, data) {
            if (data !== undefined) {
                state.errors.quantity = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.quantity = data
            }
        },
        SET_AMOUNT(state, data) {
            if (data !== undefined) {
                state.errors.amount = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.amount = data
            }
        },
        SET_CI(state, data) {
            if (data !== undefined) {
                state.errors.customer_id = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.customer_id = data
            }
        },
        SET_AI(state, data) {
            if (data !== undefined) {
                state.errors.article_id = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.article_id = data
            }
        },
        SET_MATRICULATION(state, data) {
            if (data !== undefined) {
                state.errors.matriculation = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.matriculation = data
            }
        },
        SET_CLEAN(state){
            state.errors.operation_date = "";
            state.errors.quantity = "";
            state.errors.article_id = "";
            state.errors.amount = "";
            state.errors.customer_id = "";
            state.errors.matriculation = "";
        }
    },
    actions:{
        /**
         * @param  param0 
         * @return response
         */
        async findAll({dispatch},data){
        dispatch
            let response = await axios.get('/credit-sales?page='+data.page+'&limit='+data.perPage);
            return response
        },
        async findAllSelection({dispatch},data){
            dispatch
            let response = await axios.get(`/credit-sales?selection=1&from=${data.from}&to=${data.to}${data.is_paid === 1 ? '&paid=1':''}&customer=${data.customer}`);
            return response
        },

        /**
         * @param {*} param0 
         * @param Integer id 
         */
        async findById({dispatch},id){
        dispatch
            let response = await axios.get('/credit-sales/'+id)
            return response
        },

        /**
         * @param {*} param0 
         * @param {*} data 
         */
        async save({dispatch},data){
        dispatch
            let response = await axios.post('/credit-sales',data);
            return response
        },

        /**
         * @param {*} param0 
         * @param {*} data 
         */
        async update({dispatch},data){
        dispatch
            let response = await axios.put(`/credit-sales/${data.id}`,data);
            return response
        },

        /**
         * @param {*} param0 
         * @param {*} id 
         */
        async delete({dispatch},id){
            dispatch
            let response = await axios.delete(`/credit-sales/${id}`);
            return response
        },

        async cancel({dispatch},data){
            dispatch
            let response = await axios.delete(`/credit-sales/${data.id}?mode=canceling&motif=${data.motif}`);
            return response
        }

    }
  }
  