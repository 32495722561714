import axios from "../../services/fetch"

export default {
    namespaced: true,
    state:{
        errors:{
            bank:"",
            iban:"",
            swift:"",
            bank_address:"",
            regime_fiscal:"",
            nim:"",
            signatory:"",
            signatory_title:"",
            digital_signature:"",
            jeton:"",
            jeton_expire_at:"",
            num_facture_prefixe:"",
            num_facture_size:"",
            num_facture_serie:"",
            bcc_debts:""
        }
    },
    getters: {},
    mutations:{
        SET_RF(state, data) {
            if (data !== undefined) {
                state.errors.regime_fiscal = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.regime_fiscal = data
            }
        },
        SET_NIM(state, data) {
            if (data !== undefined) {
                state.errors.nim = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.nim = data
            }
        },
        SET_SIGN(state, data) {
            if (data !== undefined) {
                state.errors.signatory = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.signatory = data
            }
        },
        SET_ST(state, data) {
            if (data !== undefined) {
                state.errors.signatory_title = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.signatory_title = data
            }
        },


        SET_BANK(state, data) {
            if (data !== undefined) {
                state.errors.bank = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.bank = data
            }
        },
        SET_IBAN(state, data) {
            if (data !== undefined) {
                state.errors.iban = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.iban = data
            }
        },
        SET_SWIFT(state, data) {
            if (data !== undefined) {
                state.errors.swift = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.swift = data
            }
        },
        SET_ADDR(state, data) {
            if (data !== undefined) {
                state.errors.bank_address = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.bank_address = data
            }
        },

        SET_NFP(state, data) {
            if (data !== undefined) {
                state.errors.num_facture_prefixe = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.num_facture_prefixe = data
            }
        },
        SET_NFS(state, data) {
            if (data !== undefined) {
                state.errors.num_facture_size = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.num_facture_size = data
            }
        },
        SET_NFSE(state, data) {
            if (data !== undefined) {
                state.errors.num_facture_serie = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.num_facture_serie = data
            }
        },

        SET_JETON(state, data) {
            if (data !== undefined) {
                state.errors.jeton = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.jeton = data
            }
        },

        SET_JETON_EXP(state, data) {
            if (data !== undefined) {
                state.errors.jeton_expire_at = Array.isArray(data) ? data[0]:data
            } else {
                state.errors.jeton_expire_at = data
            }
        },
        SET_CLEAN(state){
            state.errors.bank = ""
            state.errors.iban = ""
            state.errors.swift = ""
            state.errors.bank_address = ""
            state.errors.regime_fiscal = ""
            state.errors.nim = ""
            state.errors.signatory = ""
            state.errors.signatory_title = ""
            state.errors.digital_signature = ""
            state.errors.jeton = ""
            state.errors.jeton_expire_at = ""
            state.errors.num_facture_prefixe = ""
            state.errors.num_facture_size = ""
            state.errors.num_facture_serie = ""
        }
    },
    actions:{
        /**
         * 
         * @param  param0 
         * @return response
         */
        async findAll({dispatch}){
        dispatch
            let response = await axios.get('/settings');
            return response
        },

        /**
         * 
         * @param {*} param0 
         * @param Integer id 
         */
        async findById({dispatch},id){
        dispatch
            let response = await axios.get('/settings/'+id)
            return response
        },

        /**
         *  Store 
         * @param {*} param0 
         * @param {*} data 
         */
        async save({dispatch},data){
        dispatch
            let response = await axios.post('/settings',data);
            return response
        },


        /**
         * 
         * @param {*} param0 
         * @param {*} data 
         */
        async updateBank({dispatch},data){
        dispatch
            let response = await axios.put(`/settings/${data.id}?type_setting=bank`,data);
            return response
        },

        /**
         * 
         * @param {*} param0 
         * @param {*} data 
         */
         async updateNotification({dispatch},data){
            dispatch
                let response = await axios.put(`/settings/${data.id}?type_setting=notif`,data);
                return response
            },

        async updateJeton({dispatch},data){
        dispatch
            let response = await axios.put(`/settings/${data.id}?type_setting=auth_mecef`,data);
            return response
        },


        /**
         * 
         * @param {*} param0 
         * @param {*} data 
         */
         async updateInv({dispatch},data){
        dispatch
            let response = await axios.put(`/settings/${data.id}?type_setting=inv`,data);
            return response
        },

        /**
         * 
         * @param {*} param0 
         * @param {*} data 
         */
         async updateNumFacture({dispatch},data){
        dispatch
            let response = await axios.put(`/settings/${data.id}?type_setting=num_inv_auto`,data);
            return response
        },

        /**
         * 
         * @param {*} param0 
         * @param {*} data 
         */
         async update({dispatch},data){
        dispatch
            let response = await axios.put(`/settings/${data.id}`,data);
            return response
        },

        /**
         * 
         * @param {*} param0 
         * @param {*} id 
         */
        async delete({dispatch},id){
        dispatch
            let response = await axios.delete(`/settings/${id}`);
            return response
        },

        async deleteDigitalSignature({dispatch},id){
        dispatch
            let response = await axios.delete(`/settings/digital_signature/${id}?type_setting=inv`);
            return response
        }

    }
  }
  