import store from '@/store/index'
import router from '../../router'

class Lock {
    static async check(res){
        if(store.state.auth.connected){ 
            if(res.is_locked === 1){
                await this.lock("local")
            }else{
                if(localStorage.getItem('locked') === "true"){
                    await this.lock("local")
                }else{
                    this.unlock()
                }
            }
        } 
    }

    static timer(){
        this.mouseE()
        setInterval(()=>{
            if(store.state.lock.timer > 0) {
                store.commit('lock/SET_TIME_DECREMENT',1)
            }
            if(store.state.lock.timer === 0) this.lock()
        },1200000000000)
    //120000
    }

    static unlock(){
        localStorage.setItem('locked',false)
        store.commit('lock/SET_RESET')
    }

    static async lock(mode = "serve"){
        switch (mode) {
            case "local":
                await store.commit('lock/SET_ZERO')
                await localStorage.setItem('locked',true)
                await router.push('/locked')
                break;
            default:
                await store.dispatch('user/lockMe').then(()=>{
                    store.commit('lock/SET_ZERO')
                    localStorage.setItem('locked',true)
                    router.push('/locked')
                })
                break;
        }
        
    }
    static mouseE(){
        document.addEventListener("mousemove",()=>{
            if(localStorage.getItem('locked')) store.commit('lock/SET_RESET')
        })

        document.addEventListener("keypress",()=>{
            if(localStorage.getItem('locked')) store.commit('lock/SET_RESET')
        })

        document.addEventListener("touchstart",()=>{
            if(localStorage.getItem('locked')) store.commit('lock/SET_RESET')
        })

        document.addEventListener("touchmove",()=>{
            if(localStorage.getItem('locked')) store.commit('lock/SET_RESET')
        })
        
    }
    
}


export default Lock